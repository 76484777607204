import React from "react";
import NavegationDesktop from "./components/NavegationDesktop";

import { useBreakpoints } from "../hooks/useBreakpoints";
import AppFooter from "../layouts/components/AppFooter";
import AppDrawer from "./components/AppDrawer";

export default function MainLayout({
  children,
  styleNav,
  logoWhite,
  styleMenu,
  styleBtn,
}) {
  const { lg } = useBreakpoints();
  const links = [
    { title: "Homepage", route: "/", inHeader: false, inFooter: true },
    { title: "About", route: "/our-story", inHeader: true, inFooter: true },
    {
      title: "Services",
      route: "/our-services",
      inHeader: true,
      inFooter: true,
    },
    {
      title: "Industries",
      route: "/industries",
      inHeader: true,
      inFooter: true,
    },
    // { title: "Blogs", route: "/blogs", inHeader: true, inFooter: true },
    { title: "Contact", route: "/contact", inHeader: true, inFooter: true },
    { title: "", route: "" },
    { title: "", route: "" },
  ];
  return (
    <>
      {lg ? (
        <NavegationDesktop
          links={links}
          styleNav={styleNav}
          logoWhite={logoWhite}
          styleMenu={styleMenu}
          styleBtn={styleBtn}
        />
      ) : (
        <AppDrawer links={links} styleNav={styleNav} logoWhite={logoWhite} />
      )}
      <main className={!logoWhite && "lg:mt-36"}>{children}</main>
      <AppFooter links={links} />
    </>
  );
}
