import React from "react";
import { NavLink } from "react-router-dom";

const AppDrawer = ({ links, styleNav, logoWhite }) => {
  return (
    <div
      className={`drawer drawer-end z-10 ${
        styleNav ? styleNav : "bg-gray-ffs mb-16"
      }`}
    >
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="container">
        <div className="drawer-content flex justify-between mt-5 relative">
          <NavLink to="/">
            <img
              src={require(logoWhite
                ? "../../assets/logos/Logo-white.png"
                : "../../assets/logos/Logo.png")}
              alt="Logo Forager Financial Services"
              className="w-[152px]"
            />
          </NavLink>
          <label
            htmlFor="my-drawer-4"
            className="bg-navy-blue-ffs text-white rounded-full w-12 aspect-square grid place-content-center fixed top-5 right-4"
          >
            <i className="iconify text-4xl" data-icon="majesticons:menu" />
          </label>
        </div>
      </div>
      <div className="drawer-side">
        <label
          htmlFor="my-drawer-4"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <div className="menu py-4 pr-4 w-[70%] min-h-full bg-navy-blue-ffs relative">
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="relative"
          >
            <i
              className="iconify text-2xl text-white absolute right-3 top-4"
              data-icon="heroicons-solid:x"
            />
          </label>
          <ul className="text-white mt-24 w-full capitalize grid gap-4 font-bold">
            {links
              ?.filter((link) => link.inHeader)
              .map((link, linkIndex) => (
                <li key={linkIndex}>
                  <NavLink to={link.route}>{link.title}</NavLink>
                </li>
              ))}
          </ul>
          <img
            src={require("../../assets/logos/isotipo-full.png")}
            className="w-[85px] mt-36 ml-3"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default AppDrawer;
