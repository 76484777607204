import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { redirect } from "./constants/helpers";

import SEO from "./components/global/SEO";
import ScrollToTop from "./components/ScrollToTop.js";
import HandleToasts from "./components/HandleToasts.jsx";

import HomeScreen from "./screens/HomeScreen.jsx";
import OurStoryScreen from "./screens/OurStoryScreen.jsx";
import OurServicesScreen from "./screens/OurServicesScreen.jsx";
import IndustriesScreen from "./screens/IndustriesScreen.jsx";
// import BlogScreen from "./screens/BlogScreen.jsx";
// import Blog from "./components/Blog.jsx";
import ContactScreen from "./screens/ContactScreen.jsx";
import NotFoundScreen from "./screens/NotFoundScreen.jsx";

const App = () => {
  useEffect(() => {
    redirect();
  });

  return (
    <Router>
      <SEO />
      <ScrollToTop />
      <HandleToasts />
      <Routes>
        <Route path="/" element={<HomeScreen />} exact />
        <Route path="/our-story" element={<OurStoryScreen />} exact />
        <Route path="/our-services" element={<OurServicesScreen />} exact />
        <Route path="/industries" element={<IndustriesScreen />} exact />
        {/* <Route path="/blogs" element={<BlogScreen />} exact />
        <Route path="/blog/:slug" element={<Blog />} exact /> */}
        <Route path="/contact" element={<ContactScreen />} exact />
        <Route path="/not-found" element={<NotFoundScreen />} exact />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
