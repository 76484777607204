import React from "react";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";

import AppSubscribe from "../components/global/AppSubscribe";
import BecomePartner from "../components/global/BecomePartner";
import ForagerGivesBack from "../components/global/ForagerGivesBack";
import AppCarousel from "../components/global/AppCarousel";

const HomeScreen = () => {
  const services = [
    {
      img: require("../assets/img/home/bookkeeping.jpg"),
      title: "Monthly Bookkeeping",
    },
    {
      img: require("../assets/img/home/financial.jpg"),
      title: "Financial Statements",
    },
    {
      img: require("../assets/img/home/funding.jpg"),
      title: "Funding Assistance",
    },
    {
      img: require("../assets/img/home/financial-forecasting.jpg"),
      title: "Financial Forecasting",
    },
  ];

  return (
    <MainLayout>
      <section className="container">
        <div className="xl:max-w-[80%] xl:mx-auto">
          <div>
            <span className="h-0.5 mx-auto w-1/2 lg:w-[250px] rounded-full bg-crimson-ffs block mb-1" />
            <h1 className="text-crimson-ffs mx-auto capitalize mb-8 text-center md:w-[500px] lg:w-[800px]">
              International Collaboration For Localized Results
            </h1>
          </div>
          <div className="flex flex-col lg:flex-row gap-8 mb-24 items-center justify-between mt-[50px]">
            <div className="w-[100%] lg:w-fit">
              <h3 className="text-crimson-ffs mb-1">Cashflow Compass</h3>
              <span className="text-navy-blue-ffs">
                By Forager Financial Services
              </span>
              <p className="mt-8 mb-4">
                “The tools you need to financial prosperity with practical
                insights and expert guidance.”
              </p>
              <p>
                You will get an email with 2 financial tips and 1 question for
                you to ponder bi-weekly. If you are looking for tailored
                financial solutions for your organization’s mission enter your
                email and sign up for free right now.
              </p>
            </div>
            <div className="w-full md:w-[500px] h-[400px] md:h-[340px] -order-1 lg:order-2">
              <AppSubscribe />
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <h2 className="text-center mb-8">Tailored Services For TACH</h2>
        <p className="mb-4 xl:mb-8 lg:max-w-[776px] md:text-center lg:mx-auto">
          A realm of specialized financial services designed exclusively for
          you. Our suite recognizes the crucial role that each organization
          plays as community anchors and provides a unique support of
          affordable, high-quality accounting services.
        </p>
      </div>
      <div className="xl:container">
        <AppCarousel cards={services} isCarousel isNotCarouselOnDesktop />
      </div>
      <div className="container">
        <p className="text-2xl mt-4 xl:mt-8 mb-8 text-navy-blue-ffs font-bold text-center">
          We are not just an accounting partner; we are architects of financial
          <span className="lg:block">
            {" "}
            excellence tailored for the TACH sector (Tourism, Arts, Culture &
            Heritage).
          </span>
        </p>
        <Link
          to="/our-services"
          className="bg-crimson-ffs with-transition lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs text-white py-3 px-9 w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36"
        >
          Explore Our Services
        </Link>
      </div>
      <section className="container">
        <BecomePartner />
        <ForagerGivesBack />
      </section>
    </MainLayout>
  );
};

export default HomeScreen;
