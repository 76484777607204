import React, { useEffect, useRef, useState } from "react";

export const AppSlider = ({
  children,
  isInfinite,
  speed,
  withArrows,
  className,
}) => {
  const items = React.Children.map(children, (child) => child);
  const [duplications, setDuplications] = useState(1);
  const sliderRef = useRef(null);
  const bandRef = useRef(null);
  const [showArrows, setShowArrows] = useState({ next: true, prev: true });

  useEffect(() => {
    handleShowArrows();
    if (isInfinite) {
      calculateItemsQuantity();
    }
  }, [isInfinite]);

  function calculateItemsQuantity() {
    const duplicationQuantity = Math.ceil(
      sliderRef.current.clientWidth / bandRef.current.clientWidth,
    );
    setDuplications(duplicationQuantity * 2);
  }

  function next() {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.clientWidth,
        behavior: "smooth",
      });
      handleShowArrows();
    }
  }

  function prev() {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.clientWidth,
        behavior: "smooth",
      });
      handleShowArrows();
    }
  }

  function handleShowArrows() {
    setTimeout(() => {
      const { scrollWidth, scrollLeft, clientWidth } = sliderRef?.current || {};
      setShowArrows({
        prev: scrollLeft > 0,
        next: scrollWidth - scrollLeft > clientWidth,
      });
    }, 1000);
  }

  return (
    <div className={`overflow-hidden w-full group/slider ${className}`}>
      {withArrows && (
        <div className="w-0 h-0">
          {showArrows.prev && (
            <div className="absolute z-10 left-9 -translate-x-full grid place-items-center h-full bg-arrow-slider hover:bg-poly-cyan  with-transition">
              <button
                onClick={prev}
                className="p-3 text-white hover:text-navy-blue-ffs with-transition"
              >
                <i
                  className="iconify text-2xl"
                  style={{ transform: "translate(16%, 0%)" }}
                  data-icon="material-symbols:arrow-back-ios-rounded"
                />
              </button>
            </div>
          )}
          {showArrows.next && (
            <div className="absolute z-10 right-9 translate-x-full grid place-items-center h-full bg-arrow-slider hover:bg-poly-cyan  rotate-180 with-transition">
              <button
                onClick={next}
                className="p-3 text-white hover:text-navy-blue-ffs with-transition"
              >
                <i
                  className="iconify text-2xl"
                  style={{ transform: "translate(12%, 0%)" }}
                  data-icon="material-symbols:arrow-back-ios-rounded"
                />
              </button>
            </div>
          )}
        </div>
      )}
      <div ref={sliderRef} className="app-slider overflow-auto w-full">
        <div
          ref={bandRef}
          className={`app-slider__band flex gap-4 w-fit mt-2 ${
            isInfinite ? "move" : ""
          }`}
          style={{
            animationDuration: speed,
          }}
        >
          {Array(duplications)
            .fill(items)
            .map((itemsGroup) =>
              itemsGroup.map((items, index) => (
                <div key={index} className="flex-grow-0 flex flex-shrink-0">
                  {items}
                </div>
              )),
            )}
        </div>
      </div>
    </div>
  );
};
