import React from "react";
import MainLayout from "../layouts/MainLayout";
import AppCarousel from "../components/global/AppCarousel";
import BecomePartner from "../components/global/BecomePartner";

const IndustriesScreen = () => {
  const specificExpertise = [
    {
      img: require("../assets/img/industries/tourism.jpg"),
      title: "Tourism",
      description:
        "In the Tourism sector, where experiences are crafted and memories are made, financial intricacies require a unique touch. Forager Financial Services understands the dynamics of revenue cycles, seasonal fluctuations, and the importance of strategic financial planning in this dynamic industry.",
    },
    {
      img: require("../assets/img/industries/arts.jpg"),
      title: "Arts",
      description:
        "For the Arts sector, where creativity and expression take centre stage, financial support is vital. Our specialized services cater to the diverse financial needs of artists, galleries, and cultural organizations, ensuring they can continue to create and inspire.",
    },
    {
      img: require("../assets/img/industries/culture.jpg"),
      title: "Culture",
      description:
        "In the Culture sector, Forager Financial Services supports artists, festivals, and cultural events. We offer tailored financial solutions for artists, festival organizers, and event planners, allowing them to focus on creating enriching experiences that contribute to our cultural tapestry.",
    },
    {
      img: require("../assets/img/industries/heritage.jpg"),
      title: "Heritage",
      description:
        "In the Heritage sector, Forager Financial Services is dedicated to supporting museums, historical societies, and preservation initiatives. Collaborating with these entities, we ensure the sustainable celebration of legacies, securing a thriving future for our shared heritage.",
    },
  ];
  return (
    <MainLayout>
      <section className="container">
        <div className="xl:max-w-[80%] xl:mx-auto">
          <span className="h-0.5 mx-auto w-1/2 lg:w-[261px] rounded-full bg-crimson-ffs block mb-1" />
          <h1 className="text-crimson-ffs mx-auto capitalize mb-8 text-center md:w-[500px] lg:w-[800px]">
            The TACH Sector
          </h1>
          <div className="grid md:grid-cols-2 gap-8 mb-24 lg:mb-36 items-center">
            <div
              className="bg-cover bg-center bg-no-repeat h-[174px] md:h-[252px] rounded-br-[20px] md:order-2"
              style={{
                backgroundImage: `url(${require("../assets/img/industries/TACH.jpg")})`,
              }}
            />
            <p className="md:order-1">
              The TACH sector (tourism, arts, culture, and tourism) constitutes
              a pivotal component of societal development. It facilitates
              economic growth through tourism promotion, fosters artistic
              expression, safeguards cultural heritage, and preserves historical
              legacies. This sector serves as a cornerstone for enhancing
              societal cohesion, enriching cultural experiences, and nurturing a
              sense of identity among communities.
            </p>
          </div>
        </div>
        <h2 className="md:text-center mb-8">Passion For The TACH Sector</h2>
        <p className="mb-24 lg:mb-36 lg:max-w-[973px] md:text-center lg:mx-auto">
          In the vast landscape of financial services, specialization is not a
          choice; it’s commitment to understanding the unique nuances and
          challenges different industries face. At Forager Financial Services we
          are guided by a deep understanding of the TACH sector and the profound
          impact that these organizations have on building communities.
        </p>
        <h2 className="text-crimson-ffs mx-auto capitalize mb-4 lg:mb-8 md:text-center md:w-[500px] lg:w-[800px]">
          Sector-Specific Expertise
        </h2>
      </section>
      <div className="xl:container mb-24 lg:mb-36">
        <AppCarousel
          cards={specificExpertise}
          isCarousel
          isNotCarouselOnDesktop
        />
      </div>
      <section className="container">
        <BecomePartner />
      </section>
    </MainLayout>
  );
};

export default IndustriesScreen;
