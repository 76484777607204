import React from "react";

import logoFacebook from "../../assets/logos/facebook.svg";
import logoFacebookCrimson from "../../assets/logos/facebook-crimson.svg";
import logoFacebookDenim from "../../assets/logos/facebook-denim.svg";
import logoInstagram from "../../assets/logos/instagram.svg";
import logoInstagramCrimson from "../../assets/logos/instagram-crimson.svg";
import logoInstagramDenim from "../../assets/logos/instagram-denim.svg";
import logoLinkedln from "../../assets/logos/linkedln.svg";
import logoLinkedlnCrimson from "../../assets/logos/linkedln-crimson.svg";
import logoLinkedlnDenim from "../../assets/logos/linkedln-denim.svg";
import logoTiktok from "../../assets/logos/tiktok.svg";
import logoTiktokCrimson from "../../assets/logos/Tiktok-crimson.svg";
import logoTiktokDenim from "../../assets/logos/tiktok-denim.svg";
// import logoYoutube from "../../assets/logos/youtube.svg";
// import logoYoutubeDenim from "../../assets/logos/youtube-denim.svg";
// import logoTwitter from "../../assets/logos/twitter.svg";
// import logoTwitterDenim from "../../assets/logos/xlogo-denim.svg";

const SocialLinks = ({
  className,
  isCrimson,
  isDenim,
  hiddenNetworks = [],
}) => {
  const networks = [
    {
      name: "facebook",
      href: "https://www.facebook.com/ForagerFin",
      src: isCrimson
        ? logoFacebookCrimson
        : isDenim
        ? logoFacebookDenim
        : logoFacebook,
    },
    // {
    //   name: "twitter",
    //   href: "https://twitter.com/thevrvoyage",
    //   src: isDenim ? logoTwitterDenim : logoTwitter,
    // },
    {
      name: "instagram",
      href: "https://www.instagram.com/foragerfin",
      src: isCrimson
        ? logoInstagramCrimson
        : isDenim
        ? logoInstagramDenim
        : logoInstagram,
    },
    {
      name: "tiktok",
      href: "https://www.tiktok.com/@foragerfin",
      src: isCrimson
        ? logoTiktokCrimson
        : isDenim
        ? logoTiktokDenim
        : logoTiktok,
    },
    {
      name: "linkedin",
      href: "https://www.linkedin.com/company/foragerfin",
      src: isCrimson
        ? logoLinkedlnCrimson
        : isDenim
        ? logoLinkedlnDenim
        : logoLinkedln,
    },
    // {
    //   name: "youtube",
    //   href: "",
    //   src: isDenim ? logoYoutubeDenim : logoYoutube,
    // },
  ].filter((network) => !hiddenNetworks.includes(network.name));
  return (
    <div className={`flex gap-1 ${className}`}>
      {networks.map((ele, index) => (
        <a key={index} href={ele.href} target="_blank" rel="noreferrer">
          <img src={ele.src} alt={ele.href} />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
