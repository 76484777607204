import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "../../components/global/SocialLinks";

function AppFooter({ links }) {
  return (
    <footer>
      <div className="bg-footer text-white">
        <div className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-y-12 md:mx-auto pt-8">
          <div className="md:col-span-2">
            <img
              src={require("../../assets/logos/Logo-white.png")}
              alt=""
              className="w-44"
            />
            <h4 className="my-8 xl:mb-3">Forager Financial Services</h4>
            <p className="font-bold">
              International Collaboration For Localized Results
            </p>
            <p className="mt-8 xl:mt-4">
              An outsourced team of experienced accountants bringing high
              quality
              <span className="lg:block">
                {" "}
                financial services at cost-effective rates that fit within the
                operations
              </span>
              budgets of our partners. Together we are building stronger by
              <span className="lg:block">
                {" "}
                tackling the need for detailed accounts.
              </span>
            </p>
            <Link
              to="/contact"
              className="mt-8 xl:mt-10 block w-fit font-bold bg-white py-3 px-9 rounded-full text-navy-blue-ffs lg:hover:bg-poly-cyan with-transition z-50"
            >
              Contact Us
            </Link>
            <span className="text-navy-blue-ffs select-none">.</span>
          </div>

          <div className="lg:mx-auto">
            <h4 className="capitalize">menu</h4>
            <ul className="mt-8 grid gap-2">
              {links
                ?.filter((link) => link.inFooter)
                .map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link
                      className="text-white hover:text-poly-cyan with-transition"
                      to={link.route}
                    >
                      {link.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          <div className="grid gap-y-2 xl:gap-y-3 md:ml-auto xl:h-fit font-bold">
            <h4 className="mb-6">Contact</h4>
            <p>
              Company HQ
              <span className="block font-normal"> Gibsons BC, Canada</span>
            </p>
            <p>
              Phone
              <a
                href="https://api.whatsapp.com/send?phone=12505694401"
                target="_blank"
                rel="noreferrer"
                className="block text-white hover:text-poly-cyan font-normal with-transition"
              >
                +1 250 569 4401
              </a>
            </p>
            <p>
              General Enquiries
              <a
                href="mailto:info@foragerinternational.com"
                target="_blank"
                rel="noreferrer"
                className="block text-white hover:text-poly-cyan font-normal with-transition"
              >
                info@foragerfinancial.com
              </a>
            </p>
            <p>
              Careers
              <a
                href="mailto:careers@foragerfinancial.com"
                target="_blank"
                rel="noreferrer"
                className="block text-white hover:text-poly-cyan font-normal with-transition"
              >
                careers@foragerfinancial.com
              </a>
            </p>
          </div>

          <SocialLinks className="md:-mt-20 xl:justify-end xl:col-span-4" />

          <div className="md:col-span-2 xl:col-span-4 pb-2 mt-8 mb-5 px-5 md:px-0">
            <hr className="w-[93%] md:w-full mx-auto mb-2" />
            <p className="text-xs text-center md:text-start">
              Copyright © 2023, Forager Group Holdings LTD.
              <span className="text-xs block md:text-end mt-0.5 md:-mt-3">
                Website created by{" "}
                <span className="font-bold">
                  forager | <span className="text-orange-ffs">digital</span>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default AppFooter;
