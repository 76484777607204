import React, { cloneElement, useState } from "react";
import { useBreakpoints } from "../../hooks/useBreakpoints";

export const AppModal = ({
  children,
  activator,
  isOpen,
  styleModal,
  styleIconClose,
  styleLabelClose,
  selectedPlan,
  onClose = () => {},
}) => {
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);
  const { md } = useBreakpoints();
  function toggleOpen() {
    const newValue = !localIsOpen;
    setLocalIsOpen(newValue);
    if (newValue === false) {
      onClose?.();
    }
  }

  return (
    <>
      {activator &&
        cloneElement(activator, {
          tabIndex: 0,
          className: `${activator.props.className}`,
          onClick: toggleOpen,
        })}
      <div className={`modal bg-black/60 ${localIsOpen && "modal-open"}`}>
        <div
          className={`bg-gray modal-box w-full md:w-[90%] md:h-[93%] max-w-[966px] p-0 md:my-auto relative ${
            styleModal || ""
          }`}
        >
          <label
            className={`z-20 items-center absolute w-fit px-6 top-4 md:top-2 md:ml-auto rounded-full h-10 grid bg-navy-blue cursor-pointer ${
              styleLabelClose || ""
            }`}
            onClick={toggleOpen}
          >
            <i
              className={`iconify text-denim-ffs h-12 w-12 p-3 rounded-full ${
                md ? "absolute right-2" : "bg-white"
              } ${styleIconClose}`}
              data-icon={md ? "ic:round-close" : "ep:arrow-up-bold"}
              data-rotate="-90deg"
            />
          </label>
          {children}
        </div>
      </div>
    </>
  );
};
