import React, { useState } from "react";
import { TabContent, TabWrapper } from "../global/AppTabs";
import { OurServicesBuyPlanPayments } from "./OurServicesBuyPlanPayments";
import { OurServicesBuyPlanForm } from "./OurServicesBuyPlanForm";
import { OurServicesBuyPlanSuccess } from "./OurServicesBuyPlanSuccess";

export const OurServicesBuyPlan = ({ selectedPlan }) => {
  const [step, setStep] = useState("set-payment");
  const [selectedPayment, setSelectedPayment] = useState(null);

  function onSelectPayment(payment) {
    setSelectedPayment(payment);
  }

  return (
    <div className="px-4 md:px-11 pb-12">
      <TabWrapper value={step}>
        <TabContent name="set-payment">
          <OurServicesBuyPlanPayments
            selectedPlan={selectedPlan}
            selectPayment={onSelectPayment}
            selectedPayment={selectedPayment}
            onNext={() => setStep("fill-form")}
          />
        </TabContent>
        <TabContent name="fill-form">
          <OurServicesBuyPlanForm
            selectedPlan={selectedPlan}
            selectedPayment={selectedPayment}
            onBack={() => setStep("set-payment")}
            // onNext={() => setStep("success")}
          />
        </TabContent>
        <TabContent name="success">
          <OurServicesBuyPlanSuccess />
        </TabContent>
      </TabWrapper>
    </div>
  );
};
