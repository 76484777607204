import React from "react";

import MainLayout from "../layouts/MainLayout";
import SocialLinks from "../components/global/SocialLinks";
import BecomePartner from "../components/global/BecomePartner";
import ContactForm from "../components/ContactForm";

import PhoneIcon from "../assets/img/contact/phone-icon.svg";
import EmailIcon from "../assets/img/contact/email-icon.svg";
import SalesIcon from "../assets/img/contact/sales-icon.svg";
import WorkIcon from "../assets/img/contact/work-icon.svg";

const ContactScreen = () => {
  const contact = [
    {
      icon: PhoneIcon,
      title: "Phone / WhatsApp",
      paragraph: "+ 1 250 569 4401 ext. 108",
    },
    {
      icon: EmailIcon,
      title: "General Enquiries",
      paragraph: "info@foragerfinancial.com",
    },
    {
      icon: SalesIcon,
      title: "Sales Enquiries",
      paragraph: "sales@foragerfinancial.com",
    },
    {
      icon: WorkIcon,
      title: "Work Opportunities",
      paragraph: "careers@foragerfinancial.com",
    },
  ];

  return (
    <MainLayout>
      <span className="h-0.5 mx-auto w-1/2 lg:w-[261px] rounded-full bg-crimson-ffs block mb-1" />
      <h1 className="text-crimson-ffs mx-auto capitalize mb-8 text-center md:w-[500px] lg:w-[800px]">
        Contact Details
      </h1>
      <div className="container grid lg:grid-cols-2 gap-5 lg:gap-10 xl:gap-20">
        <div>
          <div className="grid gap-8">
            {contact.map((e, i) => (
              <div key={i} className="flex gap-5">
                <img src={e.icon} alt="Phone Icon" />
                <div>
                  <span className="h-0.5 w-16 rounded-full bg-crimson-ffs block mb-1" />
                  <h4 className="text-crimson-ffs">{e.title}</h4>
                  <p>{e.paragraph}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="my-24 lg:my-36">
            <h4 className="text-crimson-ffs">Connect with Us Online</h4>
            <p className="my-8">
              Stay updated on the latest financial insights, industry news, and
              <span className="block">
                Forager happenings by connecting with us on our social media
                channels:
              </span>
            </p>
            <SocialLinks isCrimson hiddenNetworks={["twitter", "youtube"]} />
          </div>
        </div>
        <div>
          <h4 className="text-crimson-ffs">Send Us A Message</h4>
          <p className="my-8">
            If you have specific inquiries or would like to schedule a
            consultation, please use the form below. Our dedicated team will get
            back to you promptly.
          </p>
          <ContactForm />
        </div>
        <div className="mt-24 lg:mt-36 lg:col-span-2">
          <BecomePartner isContact />
        </div>
      </div>
    </MainLayout>
  );
};

export default ContactScreen;
