import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function NavegationDesktop({
  links,
  styleNav,
  logoWhite,
  styleMenu = "text-navy-blue-ffs",
  styleBtn = "bg-navy-blue-ffs text-white lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs with-transition",
}) {
  const location = useLocation();

  return (
    <header
      className={`${
        logoWhite ? "mb-0 container" : "fixed top-0 w-full bg-gray-ffs z-[100]"
      }`}
    >
      <div className={logoWhite ? "relative" : "container"}>
        <div className={`w-full z-10 ${styleNav}`}>
          <div className="py-4 flex justify-between items-center w-full">
            <NavLink to="/">
              <img
                src={require(logoWhite
                  ? "../../assets/logos/Logo-white.png"
                  : "../../assets/logos/Logo.png")}
                alt="Logo Forager Financial Services"
                className="w-44"
              />
            </NavLink>
            <nav className={`font-bold grid place-items-center ${styleMenu}`}>
              <ul className="flex gap-10 h-full items-center">
                {links
                  ?.filter((link) => link.inHeader)
                  .map((link, linkIndex) => (
                    <li
                      className={`menu-item ${
                        link.route === location?.pathname ? "selected-menu-item" : ""
                      }`}
                      key={linkIndex}
                    >
                      <p className="with-transition menu-item__line h-0.5 w-1/2 mx-auto lg:mx-0 rounded-full" />
                      <NavLink
                        to={link.route}
                        className="menu-item__link with-transition"
                      >
                        {link.title}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavegationDesktop;
