import React from "react";

export const OurServicesCard = ({
  icon,
  title,
  paragraph,
  btnText,
  className,
  onActionClick = () => {},
}) => {
  return (
    <div
      className={`grid gap-7 py-8 px-5 lg:px-8 border border-gray-400 rounded-br-3xl w-full ${className}`}
    >
      <img src={icon} alt={`icon ${title}`} className="w-12" />
      <h3 className="text-crimson-ffs text-2xl">{title}</h3>
      <p>{paragraph}</p>
      <button
        className="mt-auto py-3 text-white font-bold bg-crimson-ffs with-transition lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs rounded-full shadow"
        onClick={() => onActionClick()}
      >
        {btnText}
      </button>
    </div>
  );
};
