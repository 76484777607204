import React, { useState, createContext, useContext, useEffect } from "react";

const TabsContext = createContext();
export const TabWrapper = ({
  children,
  value,
  renderAllContent,
  tabClass = "",
  inactiveTabClass,
  activeTabClass = "",
  onChange = () => {},
}) => {
  const [activeTab, setActiveTab] = useState(value);
  const [tabs] = useState([]);
  const values = {
    activeTab,
    setActiveTab,
    tabClass,
    inactiveTabClass,
    activeTabClass,
    renderAllContent,
    addTab(tab) {
      if (!tabs.includes(tab)) {
        tabs.push(tab);
      }
    },
  };

  useEffect(() => {
    setActiveTab(value);
  }, [value]);

  useEffect(() => {
    onChange(activeTab);

    // eslint-disable-next-line
  }, [activeTab]);

  return <TabsContext.Provider value={values}>{children}</TabsContext.Provider>;
};

export const TabLabel = ({
  className,
  children,
  name,
  onSelected = () => ({}),
}) => {
  const {
    activeTab,
    setActiveTab,
    tabClass = "",
    inactiveTabClass = "",
    activeTabClass = "",
    addTab,
  } = useContext(TabsContext);

  useEffect(() => {
    addTab(name);
    // eslint-disable-next-line
  }, []);

  return (
    <button
      className={`hover:bg-grey hover:rounded-md ${tabClass} ${
        name === activeTab ? activeTabClass : inactiveTabClass
      } ${className} with-transition`}
      onClick={() => {
        setActiveTab(name);
        onSelected();
      }}
    >
      {children}
    </button>
  );
};

export const TabContent = ({ children, className, name }) => {
  const { activeTab, renderAllContent } = useContext(TabsContext);
  if (activeTab !== name && !renderAllContent) {
    return null;
  }
  return <div className={className}>{children}</div>;
};
