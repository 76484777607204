const descriptions = [
  {
    pathname: "/",
    title: "Forager Financial Services | Streamline Your Accounting Processes",
    content: [
      "Welcome to Forager Financial Services, your partner for efficient and reliable outsourced accounting solutions. Simplify your financial management with our expert team.",
    ],
    keywords: [
      "Outsourced accounting solutions",
      "Accounting services",
      "Financial management",
      "Accountancy firm",
      "Professional accounting",
      "Financial outsourcing",
      "Accounting expertise",
      "Efficient accounting",
      "Reliable accounting services",
      "Streamlined financial processes",
      "Expert accountants",
      "Accounting solutions provider",
      "Financial management services",
      "Budget-friendly accounting",
      "Outsourced finance department",
      "Expert financial advisors",
      "Accounting consultation",
      "Accounting efficiency",
      "Financial optimization",
      "Accounting support",
    ],
  },
  {
    pathname: "/our-story",
    title: "About Forager Financial Services | Your Trusted Accounting Partner",
    content: [
      "Learn about Forager Financial Services, a dedicated team offering outsourced accounting services tailored to meet your business needs. Discover our commitment to accuracy and efficiency.",
    ],
    keywords: [
      "Accounting partner",
      "Outsourced accounting services",
      "Accounting team",
      "Business accounting solutions",
      "Financial accuracy",
      "Efficiency in accounting",
      "Tailored accounting services",
      "Accounting commitment",
      "Trusted accounting firm",
      "Business finance management",
      "Accounting expertise",
      "Financial consulting",
      "Accounting professionalism",
      "Dedicated accounting team",
      "Business finance solutions",
      "Accounting consultation",
      "Financial management services",
      "Accounting reliability",
      "Business financial support",
      "Financial efficiency",
    ],
  },
  {
    pathname: "/our-services",
    title: "Our Services | Comprehensive Outsourced Accounting Solutions",
    content: [
      "Explore Forager Financial Services' range of outsourced accounting solutions, including bookkeeping, payroll processing, tax preparation, financial statement preparation, and general ledger accounting.",
    ],
    keywords: [
      "Outsourced accounting solutions",
      "Accounting services",
      "Bookkeeping services",
      "Payroll processing",
      "Tax preparation",
      "Financial statement preparation",
      "General ledger accounting",
      "Accounting solutions provider",
      "Comprehensive accounting services",
      "Outsourced finance department",
      "Professional accounting services",
      "Financial management solutions",
      "Budget-friendly accounting",
      "Expert accounting team",
      "Accounting consultation",
      "Financial optimization",
      "Accounting support",
      "Small business accounting",
      "Business finance management",
      "Financial efficiency",
    ],
  },
  {
    pathname: "/industries",
    title:
      "Industries We Serve | Tailored Accounting Solutions for Your Sector",
    content: [
      "Forager Financial Services provides specialized accounting services for various industries. Whether you're in retail, hospitality, or healthcare, trust us to manage your finances effectively.",
    ],
    keywords: [
      "Tailored accounting solutions",
      "Industry-specific accounting",
      "Accounting services for retail",
      "Accounting services for hospitality",
      "Accounting services for healthcare",
      "Industry-focused accounting",
      "Sector-specific accounting solutions",
      "Retail accounting",
      "Hospitality finance management",
      "Healthcare accounting services",
      "Industry finance management",
      "Accounting for retail businesses",
      "Hospitality accounting solutions",
      "Healthcare finance management",
      "Industry accounting expertise",
      "Sector-specific financial management",
      "Retail finance optimization",
      "Hospitality financial consulting",
      "Healthcare financial support",
      "Industry finance efficiency",
    ],
  },
  {
    pathname: "/blogs",
    title: "Explore Our Blog | Insights for Financial Management Professionals",
    content: [
      "Dive into Forager Financial Services' blog catalogue for valuable insights, tips, and trends in accounting, bookkeeping, payroll, tax preparation, and financial management.",
    ],
    keywords: [
      "Financial management insights",
      "Accounting trends",
      "Bookkeeping tips",
      "Payroll processing insights",
      "Tax preparation trends",
      "Financial management strategies",
      "Blog catalogue",
      "Accounting insights",
      "Financial management trends",
      "Bookkeeping techniques",
      "Payroll processing tips",
      "Tax preparation insights",
      "Financial management advice",
      "Accounting tips",
      "Bookkeeping trends",
      "Payroll processing trends",
      "Tax preparation tips",
      "Financial management resources",
      "Accounting best practices",
      "Financial management articles",
    ],
  },
  {
    pathname: "/contact",
    title:
      "Contact Forager Financial Services | Let's Optimize Your Financial Processes",
    content: [
      "Reach out to Forager Financial Services to discuss how our outsourced accounting solutions can benefit your business. Start streamlining your financial processes today.",
    ],
    keywords: [
      "Contact Forager Financial Services",
      "Outsourced accounting solutions",
      "Financial process optimization",
      "Accounting consultation",
      "Business finance management",
      "Streamlining financial processes",
      "Accounting services inquiry",
      "Financial efficiency consultation",
      "Outsourced finance department",
      "Business financial support",
      "Financial management solutions",
      "Accounting optimization",
      "Financial process improvement",
      "Accounting support inquiry",
      "Financial consultation",
      "Business finance optimization",
      "Streamlined financial management",
      "Financial process streamlining",
      "Business finance consultation",
      "Financial management partnership",
    ],
  },
  {
    pathname: "/not-found",
    title: "Error 404",
    content: [""],
    keywords: [""],
  },
  //   {
  //     pathname: "/",
  //     content: [""],
  //     keywords: [""],
  //   },
];

export default descriptions;
