import React, { useState } from "react";

import { useBreakpoints } from "../hooks/useBreakpoints";

import MainLayout from "../layouts/MainLayout";
import BecomePartner from "../components/global/BecomePartner";
import { TabContent, TabLabel, TabWrapper } from "../components/global/AppTabs";
import { OurServicesCard } from "../components/our-services/OurServicesCard";
import { OurServicesBuyPlan } from "../components/our-services/OurServicesBuyPlan";
import { AppModal } from "../components/global/AppModal";

import iconStar from "../assets/img/star-icon.svg";
import iconDoubleStar from "../assets/img/double-star-icon.svg";
import iconDoc from "../assets/img/doc-icon.svg";
import iconMoney from "../assets/img/money-icon.svg";
import iconGraphs from "../assets/img/graphs-icon.svg";
import iconSettingsMoney from "../assets/img/settings-money-icon.svg";
import iconBook from "../assets/img/book-icon.svg";
import iconCanculator from "../assets/img/calculator-icon.svg";
import { AppSlider } from "../components/global/AppSlider";

const OurServicesScreen = () => {
  const { md } = useBreakpoints();

  const bundles = [
    {
      icon: iconStar,
      title: "Essentials",
      description:
        "Tracking expenses and managing employee compensation. This bundle offers a solution to streamline the monthly basics for your organization.",
      paragraph:
        "Get peace of mind with monthly bookkeeping and payroll processing services.",
      services: ["Monthly Bookkeeping", "Payroll Processing"],
      payments: [
        {
          name: "Tier 1",
          price: "$378/month",
          include: ["1 - 50 transactions", "Up to 5 employees"],
        },
        {
          name: "Tier 2",
          price: "$757/month",
          include: ["51 – 100 transactions", "Up to 10 employees"],
        },
        {
          name: "Tier 3",
          price: "$1,198/month",
          include: ["101 – 150 transactions", "Up to 20 employees"],
        },
        {
          name: "Tier 4",
          price: "$1,640/month",
          include: ["151 – 200 transactions", "Up to 30 employees"],
        },
      ],
      btnText: "See plan",
      className: "lg:w-96",
    },
    {
      icon: iconDoubleStar,
      title: "Premium",
      description:
        "Tracking expenses and managing employee compensation to ensuring regulatory compliance and optimizing tax strategies, we offer a solution to streamline your financial operations.",
      paragraph:
        "Outsource all your accounting with bookkeeping, payroll, tax filing, and financial statement preparation.",
      services: [
        "Monthly Bookkeeping",
        "Payroll Processing",
        "Tax Accounting",
        "Financial Statement Preparation",
      ],
      payments: [
        {
          name: "Tier 1",
          price: "$429/month",
          include: [
            "1 – 50 transactions",
            "Up to 5 employees",
            "$100,000/year",
          ],
        },
        {
          name: "Tier 2",
          price: "$848/month",
          include: [
            "51 – 100 transactions",
            "Up to 10 employees",
            "$250,000/year",
          ],
        },
        {
          name: "Tier 3",
          price: "$1,331/month",
          include: [
            "101 – 150 transactions",
            "Up to 20 employees",
            "$500,000/year",
          ],
        },
        {
          name: "Tier 4",
          price: "$1,823/month",
          include: [
            "151 – 200 transactions",
            "Up to 30 employees",
            "$500,000+/year",
          ],
        },
      ],
      btnText: "See plan",
      className: "lg:w-96",
    },
  ];

  const single = [
    {
      icon: iconDoc,
      title: "Monthly Bookkeeping",
      paragraph:
        "We go beyond the traditional accounting services to ensure your financial records are not just organized but optimized.",
      description:
        "We go beyond the traditional accounting services to ensure your financial records are not just organized but optimized.",
      btnText: "See plan",
      services: ["Monthly Bookkeeping"],
      payments: [
        {
          name: "Tier 1",
          price: "$315/month",
          include: ["1 - 50 transactions"],
        },
        {
          name: "Tier 2",
          price: "$631/month",
          include: ["51 - 100 transactions"],
        },
        {
          name: "Tier 3",
          price: "$946/month",
          include: ["101 – 150 transactions"],
        },
        {
          name: "Tier 4",
          price: "$1,262/month",
          include: ["151 – 200 transactions"],
        },
      ],
      className: "lg:w-[278px] lg:carousel-item lg:flex-col",
    },
    {
      icon: iconMoney,
      title: "Payroll Processing",
      paragraph:
        "Our report covers expenses, tax withholdings, compliance, and recommendations for optimization.",
      description:
        "Our report covers expenses, tax withholdings, compliance, and recommendations for optimization.",
      btnText: "See plan",
      services: ["Payroll Processing"],
      payments: [
        {
          name: "Tier 1",
          price: "$63/month",
          include: ["1 – 5 employees"],
        },
        {
          name: "Tier 2",
          price: "$126/month",
          include: ["6 – 10 employees"],
        },
        {
          name: "Tier 3",
          price: "$252/month",
          include: ["11 – 20 employees"],
        },
        {
          name: "Tier 4",
          price: "$378/month",
          include: ["21 – 30 employees"],
        },
      ],
      className: "lg:w-[278px] lg:carousel-item lg:flex-col",
    },
    {
      icon: iconGraphs,
      title: "Financial Statements",
      paragraph:
        "We craft concise and comprehensive financial reports, providing clarity and insights into your company’s financial health and status.",
      description:
        "Streamline overview. Highlight key transactions, balances, and trends, offering essential insights into financial activities for informed decision-making.",
      btnText: "See plan",
      services: ["Financial Statements"],
      payments: [
        {
          name: "Cost/Hour",
          price: "$27/hour",
        },
      ],
      className: "lg:w-[278px] lg:carousel-item lg:flex-col",
    },
    {
      icon: iconSettingsMoney,
      title: "Funding Assistance",
      paragraph:
        "We have a track record of helping companies acquire diverse funding venues, ensuring financial resources efficiently and effectively.",
      description:
        "We have a track record of helping companies acquire diverse funding venues, ensuring financial resources efficiently and effectively.",
      btnText: "See plan",
      services: ["Financial Assistance"],
      payments: [
        {
          name: "Cost/Hour",
          price: "$43/hour",
        },
      ],
      className: "lg:w-[278px] lg:carousel-item lg:flex-col",
    },
    {
      icon: iconBook,
      title: "Ledger Accounts",
      paragraph:
        "Streamline overview. Highlight key transactions, balances, and trends, offering essential insights into financial activities for informed decision-making.",
      description:
        "Streamline overview. Highlight key transactions, balances, and trends, offering essential insights into financial activities for informed decision-making.",
      btnText: "See plan",
      services: ["Ledger Accounts"],
      payments: [
        {
          name: "Tier 1",
          price: "$1,340/month",
          include: ["$1 - $100,000/year"],
        },
        {
          name: "Tier 2",
          price: "$2,010/month",
          include: ["$100,001 - $250,000/year"],
        },
        {
          name: "Tier 3",
          price: "$2,680/month",
          include: ["$250,001 - $500,000/year"],
        },
        {
          name: "Tier 4",
          price: "$3,350/month",
          include: ["$500,000+ /year"],
        },
      ],
      className: "lg:w-[278px] lg:carousel-item lg:flex-col",
    },
    {
      icon: iconCanculator,
      title: "Tax Accounting",
      paragraph:
        "Detailed tax liabilities, deductions, compliance, and strategic recommendations for optimal tax management.",
      description:
        "Detailed tax liabilities, deductions, compliance, and strategic recommendations for optimal tax management.",
      services: ["Tax Accounting"],
      payments: [
        {
          name: "Tier 1",
          price: "$285/year",
          include: ["$1 - $100,000/year"],
        },
        {
          name: "Tier 2",
          price: "$427/year",
          include: ["$100,001 - $250,000/year"],
        },
        {
          name: "Tier 3",
          price: "$569/year",
          include: ["$250,001 - $500,000/year"],
        },
        {
          name: "Tier 4",
          price: "$854/year",
          include: ["$500,000+ /year"],
        },
      ],
      btnText: "See plan",
      className: "lg:w-[278px] lg:carousel-item lg:flex-col",
    },
  ];

  const [selectedPlan, setSelectedPlan] = useState(null);

  function onSelectPlan(planCard) {
    setSelectedPlan(planCard);
  }

  return (
    <MainLayout>
      <section>
        <div className="container">
          <div className="xl:max-w-[80%] mx-auto mb-20">
            <span className="h-0.5 mx-auto w-1/2 lg:w-[261px] rounded-full bg-crimson-ffs block mb-1" />
            <h1 className="text-center">Our Services</h1>
            <p className="my-8 md:text-center max-w-[971px] mx-auto">
              We understand the unique challenges and opportunities that
              organizations face in the TACH sector. Our suite of services is
              crafted to meet your needs and empower your streamline operations.
            </p>
          </div>
        </div>

        <TabWrapper
          activeTabClass="bg-poly-cyan with-transition"
          tabClass="py-4 px-3 rounded-lg"
          value="bundles-services"
        >
          <div className="container">
            <div className="grid grid-cols-2 max-w-xl mx-auto p-0.5 mb-8 border border-gray-400 rounded-xl text-navy-blue-ffs font-bold">
              <TabLabel
                name="bundles-services"
                className="hover:bg-poly-cyan hover:opacity-75"
              >
                Bundles
              </TabLabel>
              <TabLabel
                name="single-services"
                className="hover:bg-poly-cyan hover:opacity-75"
              >
                Single Services
              </TabLabel>
            </div>
          </div>

          <TabContent
            name="bundles-services"
            className="flex flex-wrap justify-center gap-5 container"
          >
            {bundles.map((e, i) => (
              <OurServicesCard
                key={i}
                {...e}
                onActionClick={() => onSelectPlan(e)}
              />
            ))}
          </TabContent>

          <TabContent
            name="single-services"
            className="flex flex-wrap justify-center gap-5 container relative"
          >
            <AppSlider withArrows>
              {single.map((e, i) => (
                <OurServicesCard
                  key={i}
                  {...e}
                  onActionClick={() => onSelectPlan(e)}
                />
              ))}
            </AppSlider>
          </TabContent>
        </TabWrapper>

        <div className="mt-24 lg:mt-36 container">
          <BecomePartner />
        </div>
      </section>
      {selectedPlan && (
        <AppModal
          isOpen
          styleModal={`bg-gray-bg-ffs ${!md && "rounded-none min-h-screen"}`}
          styleLabelClose="sticky top-1"
          onClose={() => setSelectedPlan(null)}
          selectedPlan={selectedPlan}
        >
          <OurServicesBuyPlan selectedPlan={selectedPlan} />
        </AppModal>
      )}
    </MainLayout>
  );
};

export default OurServicesScreen;
