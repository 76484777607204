import React from "react";
import { useSelector } from "react-redux";
import Toast from "./Toast";

const HandleToasts = () => {
  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const {
    loading: sendingMail,
    error: failedToSent,
    success: mailSent,
  } = contactMailCreate;

  return (
    <>
      {sendingMail ? <Toast loading body /> : <></>}
      {mailSent ? <Toast success body="Mail sent successfully" /> : <></>}
      {failedToSent ? <Toast error body={failedToSent} /> : <></>}
    </>
  );
};

export default HandleToasts;
