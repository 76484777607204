import React from "react";
import { Link } from "react-router-dom";

const BecomePartner = ({ isContact }) => {
  return (
    <div className="xl:max-w-[80%] xl:mx-auto grid md:grid-cols-2 gap-8 xl:gap-[75px] mb-24 lg:mb-36 md:items-center">
      <div
        className="bg-cover bg-no-repeat h-[417px] md:h-[458px] rounded-br-[20px]"
        style={{
          backgroundImage: `url(${require("../../assets/img/home/partner.jpg")})`,
        }}
      />
      <div>
        <h2 className="lg:text-[52px] xl:text-[56px] whitespace-nowrap">
          Become A Partner
        </h2>
        <p className="my-8">
          All Forager International brands support partners in the TACH
          (tourism, arts, culture, and heritage) sector. Startups, family-run
          restaurants, museums, art galleries, hotels, independent tour
          operators, and many more are all supported through this initiative.
        </p>
        <h4 className="text-crimson-ffs mb-4">Benefits:</h4>
        <ul className="list-disc pl-6">
          <li>Exclusive discounts</li>
          <li>Access to a resource library</li>
          <li>Collaboration opportunities</li>
        </ul>
        <Link
          to="/contact"
          className={`bg-crimson-ffs with-transition lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs mt-8 text-white py-3 lg:px-9 lg:w-fit mx-auto lg:mx-0 block text-center rounded-full font-bold ${
            isContact && "hidden h-fit"
          }`}
        >
          Join Us
        </Link>
      </div>
    </div>
  );
};

export default BecomePartner;
