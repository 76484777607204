import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBreakpoints } from "../hooks/useBreakpoints";

import MainLayout from "../layouts/MainLayout";

const NotFoundScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/not-found") {
      navigate(`/not-found`);
    }

    // eslint-disable-next-line
  }, [navigate]);

  const { lg } = useBreakpoints();

  return (
    <MainLayout
      styleNav="absolute"
      styleBtn="bg-white text-navy-blue-ffs"
      styleMenu="text-white"
      logoWhite
    >
      <div
        className="h-screen bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${require(lg
            ? "../assets/img/bg-not-found.jpg"
            : "../assets/img/bg-not-found-mobile.jpg")})`,
        }}
      >
        <div className="container text-white pt-36 lg:pt-72 relative h-full">
          <p className="bg-white h-0.5 w-40 rounded-full" />
          <h1 className="mb-2 lg:text-[56px] text-white">Page Not Found</h1>
          <p className="text-2xl lg:w-[630px]">
            It looks like this page has moved somewhere else. come back to the
            homepage
          </p>
          <img
            src={require("../assets/logos/isotipo.png")}
            alt=""
            className="w-32 lg:w-52 absolute bottom-0 lg:right-16"
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default NotFoundScreen;
