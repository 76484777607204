import React from "react";
import MainLayout from "../layouts/MainLayout";
import ForagerGivesBack from "../components/global/ForagerGivesBack";

const OurStoryScreen = () => {
  return (
    <MainLayout>
      <div className="container mb-24 lg:mb-36 xl:max-w-[80%] xl:mx-auto">
        <span className="h-0.5 mx-auto w-1/2 lg:w-[261px] rounded-full bg-crimson-ffs block mb-1" />
        <h1 className="text-center">Our Story</h1>
        <p className="my-8 md:text-center max-w-[971px] mx-auto">
          Inspired by Forager International’s transformative work in
          community-led initiatives, Anderson Guerrero envisioned a new era of
          support for the Tourism, Arts, Culture and Heritage (TACH) sector.
          Recognizing the crucial role these organizations play as community
          anchors, he founded Forager Financial Services to provide affordable,
          high-quality accounting services.
        </p>
        <div className="lg:mt-14 bg-navy-blue-ffs rounded-br-[20px] text-white flex font-bold gap-8 h-[94px] md:h-[137px] max-w-[475px] mx-auto">
          <img
            src={require("../assets/img/about/anderson-guerrero.png")}
            alt="Anderson Guerrero"
            className="rounded-br-[20px]"
          />
          <p className="py-7 md:py-11">
            Anderson Guerrero
            <span className="block font-normal">Co- Founder</span>
          </p>
        </div>
      </div>

      <div className="container xl:max-w-[80%] xl:mx-auto mb-24 lg:mb-36 grid items-center">
        <div className="relative grid gap-8 lg:max-w-[70%]">
          <h2>Our Journey</h2>
          <p>
            Guided by the transformative work of Forager International, which
            champions innovative support services and community-led initiatives,
            Anderson Guerrero Gomez envisioned a unique form of support for the
            Tourism, Arts, Culture, and Heritage (TACH) sector. He saw an
            opportunity to redefine the narrative, providing a distinctive brand
            of assistance that goes beyond traditional financial services.
          </p>
          <p>
            Anderson's journey began by acknowledging the shortcomings of large
            institutions and multinationals in fostering respectful work
            environments. Witnessing these gaps firsthand, he recognized the
            vital role that organizations in the TACH sector play as anchors in
            their communities. This realization became the driving force behind
            the creation of Forager Financial Services.
          </p>
          <p>
            With a steadfast commitment to positive change, Anderson founded
            Forager Financial Services with a clear vision – to offer
            affordable, high-quality accounting services tailored to the unique
            needs of the TACH sector. His mission extended beyond the balance
            sheets and profit margins. Anderson aimed to create an environment
            where financial excellence coexists with a happy and respectful
            workplace culture.
          </p>
          <p>
            By assembling a team of professionals with diverse experiences from
            leading multinational companies, academic institutions, and large
            Latin American organizations, Anderson ensured that Forager
            Financial Services embodied a commitment to excellence and respect.
            His own experiences working in multinational companies highlighted a
            pervasive gap in the financial service industry, especially in
            developing countries, where the absence of respectful work
            environments and the neglect of a healthy work-life balance were
            glaring issues.
          </p>
        </div>
        <div className="grid grid-cols-6 gap-8 items-center mt-8 lg:max-w-[60%]">
          <p className="col-span-4 md:col-span-5 lg:col-span-6 pr-3">
            Fueling his passion to bridge this gap, Anderson's vision for
            Forager Financial Services transcends mere financial transactions.
            It is a commitment to creating positive change within the TACH
            sector, supporting organizations that serve as community anchors. By
            fostering a workplace culture that prioritizes happiness, respect,
            and engagement, Anderson seeks to not only elevate financial
            services but also contribute to the holistic well-being of the
            organizations that form the backbone of our communities.
          </p>
          <img
            src={require("../assets/img/about/isotipo.png")}
            alt="Anderson Guerrero"
            className="lg:hidden col-span-2 md:col-span-1 w-[163px] md:w-[133px] lg:w-[336px] absolute right-0 pl-8 pt-8 lg:top-1/2"
          />
        </div>
        <img
          src={require("../assets/img/about/isotipo.png")}
          alt="Anderson Guerrero"
          className="hidden lg:block w-[336px] absolute right-0 pl-8"
        />
      </div>

      <div className="container xl:max-w-[80%] xl:mx-auto">
        <div>
          <div className="mb-24 lg:mb-36 grid items-center gap-8 lg:gap-24 md:grid-cols-2">
            <div className="md:order-2 grid gap-8 lg:py-3">
              <h2>Our Ethos</h2>
              <p>
                Forager Financial Services is committed to providing the highest
                quality results at affordable rates, recognizing the budget
                constraints faced by cultural institutions, family-run
                restaurants, startups, and independent tour operators. Our ethos
                revolves around creating opportunities for skilled workers in
                Latin America, emphasizing a work-life balance, and fair payment
                structures. We believe that quality service should not come at
                the cost of compromising respect for our team.
              </p>
              <p>
                The specialization in the TACH sector allows us to extend these
                benefits to organizations that may not always have the capacity
                for quality and respectful work without sacrificing financial
                constraints. By collaborating with partners in the TACH sector,
                we have developed services that address their unique needs,
                ensuring accuracy and reliability in financial management.
              </p>
            </div>
            <div
              className="md:order-1 bg-cover bg-no-repeat bg-center h-[361px] md:h-full xl:h-[440px] rounded-br-[20px]"
              style={{
                backgroundImage: `url(${require("../assets/img/about/ethos.jpg")})`,
              }}
            />
          </div>
        </div>
      </div>

      <div className="container mb-24 lg:mb-36">
        <ForagerGivesBack />
      </div>
    </MainLayout>
  );
};

export default OurStoryScreen;
