import React, { useEffect, useState } from "react";
import { useBreakpoints } from "../../hooks/useBreakpoints";

const AppSubscribe = () => {
  const { md } = useBreakpoints();
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let myIframe = document.getElementsByClassName("for-emailoctopus");

    setTimeout(() => {
      const objToDestroy =
        myIframe?.myiframe?.contentDocument.children[0]?.children[1].children[1]
          ?.children[0]?.children[1]?.children[2];
      if (objToDestroy) objToDestroy.style.display = "none";

      setOpacity(100);
    }, 1000);
  });

  return (
    <iframe
      className="for-emailoctopus bg-cover bg-no-repeat bg-center rounded-br-[20px] flex justify-center items-center mx-auto with-transition"
      title="newsletter"
      style={{
        width: md ? "500px" : "100%",
        height: "100%",
        overflow: "auto",
        backgroundImage: `url(${require("../../assets/img/bg-form.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        opacity: opacity,
      }}
      name="myiframe"
      id="myiframe"
      // srcDoc={`<html><body><script async src="https://eocampaign1.com/form/a9ce83b2-e882-11ee-a9f2-15f97d6087fe.js" data-form="a9ce83b2-e882-11ee-a9f2-15f97d6087fe"></script></body></html>`}
      srcDoc={`<html><body><script async src="https://eocampaign1.com/form/2f51defc-e7bc-11ee-8bb2-b52f505442fa.js" data-form="2f51defc-e7bc-11ee-8bb2-b52f505442fa"></script></body></html>`}
    >
      ...
    </iframe>
    // <div
    //   className="md:order-2 bg-cover bg-no-repeat bg-center rounded-br-[20px] px-5 md:px-14 py-8 lg:py-12"
    //   style={{
    //     backgroundImage: `url(${require("../../assets/img/bg-form.jpg")})`,
    //   }}
    // >
    //   <h4 className="text-white leading-7 text-center mb-8">
    //     Get the “Cashflow Compass”
    //     <span className="lg:block">newsletter in your inbox</span>
    //   </h4>
    //   <form className="flex flex-col gap-3">
    //     <input
    //       type="text"
    //       placeholder="Your email address"
    //       className="w-full rounded-xl px-5 py-3"
    //     />
    //     <button
    //       type="submit"
    //       className="text-navy-blue-ffs lg:hover:text-white w-full bg-poly-cyan lg:hover:bg-crimson-ffs with-transition text-center font-bold rounded-full py-3"
    //     >
    //       Subscribe
    //     </button>
    //   </form>
    // </div>
  );
};

export default AppSubscribe;
