import React from "react";
import { useBreakpoints } from "../../hooks/useBreakpoints";

const AppCarousel = ({
  cards,
  isCarousel,
  isNotCarouselOnDesktop,
  styleCards = "w-[277px] lg:w-full h-[442px]",
}) => {
  const { lg } = useBreakpoints();
  return (
    <div
      className={`w-full p-4 xl:p-0 space-x-4 bg-neutral 
      ${isNotCarouselOnDesktop && lg && "grid grid-cols-4 xl:justify-center"}
        ${isCarousel && "carousel carousel-center"}`}
    >
      {cards.map((e, i) => (
        <div key={i} className="carousel-item">
          <div
            className={`animated-card flex relative bg-cover bg-center bg-no-repeat rounded-br-[30px] px-7 py-12 ${
              !e.description && "flex items-end"
            } ${styleCards}`}
            style={{ backgroundImage: `url(${e.img})` }}
          >
            <h3
              className={`text-white ${
                e.description && "animated-card__title absolute"
              }`}
            >
              <span className="h-0.5 w-20 rounded-full bg-white block mb-1" />
              {e.title}
            </h3>
            {e.description && (
              <p className="animated-card__text text-white mt-[73px]">
                {e.description}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppCarousel;
